import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation, Trans } from 'react-i18next';

function GamePermissionsTableHead(props) {
  const { headers, onClick } = props;

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell onClick={() => {onClick(headCell.id)}} sx={{ width: headCell.width, textAlign: headCell.align, cursor: ['usable', 'visible'].includes(headCell.id) ? 'pointer' : 'auto' }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

GamePermissionsTableHead.propTypes = {
  headers: PropTypes.array.isRequired
};

function GamePermissionsTableToolbar(props) {
  const { hasChanges, saveIcon, deleteIcon } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(hasChanges && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {hasChanges ? (
        <>
          <Tooltip title={t("Save changes")}>
            <IconButton color="success" size="large" onClick={props.onSave}>
              {saveIcon}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Discard changes")}>
          <IconButton color="error" size="large" onClick={props.onDiscard}>
            {deleteIcon}
          </IconButton>
        </Tooltip>
        </>
      ) : (
        null
      )}
    </Toolbar>
  );
}

GamePermissionsTableToolbar.propTypes = {
  saveIcon: PropTypes.object.isRequired,
  deleteIcon: PropTypes.object.isRequired,
};

export default function GamePermissionsTable({headers, gamesInfo, onAction, saveIcon, deleteIcon, showPrivate, privateList}) {
  const [hasChanges, setHasChanges] = React.useState(false);
  const [rows, setRows] = React.useState(gamesInfo.filter((elem) => (showPrivate ? privateList.includes(elem.id) : !privateList.includes(elem.id))).map((g) => ({...g})));
  const [visibleToggle, setVisibleToggle] = React.useState(false);
  const [usableToggle, setUsableToggle] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setRows(gamesInfo.filter((elem) => (showPrivate ? privateList.includes(elem.id) : !privateList.includes(elem.id))).map((g) => ({...g})));
  }, gamesInfo)

  const handleChangeVisible = (idx) => {
    rows[idx].visible = !rows[idx].visible;
    if (!rows[idx].visible) {
      rows[idx].usable = false;
    }
    setRows([...rows]);
    setHasChanges(true);
  };

  const handleChangeUsable = (idx) => {
    rows[idx].usable = !rows[idx].usable;
    setRows([...rows]);
    setHasChanges(true);
  };

  const handleSelectAll = (id) => {
    if (id === 'visible') {
      rows.forEach((row) => {
        if (visibleToggle) {
          row.usable = false;
        }
        row.visible = !visibleToggle;
      });

      if (visibleToggle) {
        setUsableToggle(false);
      }
      setVisibleToggle(!visibleToggle);
    } else if (id === 'usable') {
      rows.forEach((row) => {
        if(row.visible ) {
          row.usable = !usableToggle;
        }
      });

      setUsableToggle(!usableToggle);
    }
    setHasChanges(true);
    return;
  }

  return (
    <Box sx={{ width: '90%' }}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <GamePermissionsTableHead
              headers={headers}
              hasChanges={hasChanges}
              rowCount={rows.length}
              onClick={handleSelectAll}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={t(row.name)}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Trans>{row.name}</Trans>
                      </TableCell>
                      <TableCell padding="checkbox" sx={{textAlign: 'center'}}>
                        <Checkbox
                          color="primary"
                          checked={row.visible}
                          onChange={() => handleChangeVisible(index)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="checkbox" sx={{textAlign: 'center'}}>
                        <Checkbox
                          color="primary"
                          checked={row.usable}
                          onChange={() => handleChangeUsable(index)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          disabled={!row.visible}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <GamePermissionsTableToolbar 
            hasChanges={hasChanges} 
            saveIcon={saveIcon}
            deleteIcon={deleteIcon}
            onSave={() => {
              onAction(rows);
              setHasChanges(false);
            }}
            onDiscard={() => {
              setRows([...gamesInfo]);
              setHasChanges(false);
          }}
        />
    </Box>
  );
}
