import * as React from "react"
import { db } from '../../../firebase-info';
import { collection, getDocs, updateDoc} from "firebase/firestore";
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography
} from "@mui/material"
import { GamesContext } from '../../contexts/contexts.js';
import BeGeneralPage from "../../components/be-general-page";
import GamePermissionsTable from "../../components/GamePermissionsTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DEFAULT_GAME_PERMISSIONS from '../../utils/defaultGamePermissions';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

const PRIVATE_GAMES_IDS = [
  'raven',
  'dishonestyaltruistic',
  'dishonestygroup',
  'dishonestyindividual',
];

// markup
const ManagePermissionsPage = () => {
	const { t } = useTranslation();
  const gamesInfo = React.useContext(GamesContext);
  const [recentResearchers, setRecentResearchers] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [userSelected, setUserSelected] = React.useState(null);

    const headers = [
        {
          id: 'game',
          align: 'left',
          width: '60%',
          label: t('Game'),
        },
        {
          id: 'visible',
          align: 'center',
          width: '20%',
          label: t('Can be seen'),
        },
        {
          id: 'usable',
          align: 'center',
          width: '20%',
          label: t('Can be used'),
        },
      ];

  function compare(propName) {
    return function(a,b) {
        if (a[propName] < b[propName])
            return -1;
        if (a[propName] > b[propName])
            return 1;
        return 0;
    };
  }

  function buildGamePermissionsFromReseacher(researcher){
    const permissions = [];

    if(researcher){
      if(!researcher.gamePermissions) {
        researcher.gamePermissions = {...DEFAULT_GAME_PERMISSIONS};
      }
      const gamePermissions = researcher.gamePermissions;
      const values = Object.values(gamePermissions);
      const keys = Object.keys(gamePermissions);
      keys.forEach((g) => {
        permissions.push({
          id: g,
          name: gamesInfo.find((elem) => (elem.id === g)).title
        })
      })
      values.forEach((v, idx) => {
        permissions[idx]["visible"] = v.visible;
        permissions[idx]["usable"] = v.usable;
      })
    }

    return permissions.sort(compare('id'));
  }

	async function getDocumentData() {
    const researchers = [];
    const q =  await getDocs(collection(db, "users"));
    q.forEach((doc) => {
      researchers.push({
          id: doc.id,
          ref: doc.ref,
          ...doc.data(),
      });
    });

    setRecentResearchers(researchers);
    if (researchers.length) {
      setUserSelected(researchers[0]);
    }
    setLoading(false);
	};

  function buildChangesToPermissions(changes = []){
    const permissions = {};
    if(!changes) return permissions;

    changes.forEach((g) => {
      permissions[g.id] = {
        visible: g.visible,
        usable: g.usable,
      }
    });

    userSelected.gamePermissions = {...userSelected.gamePermissions, ...permissions}

    return userSelected.gamePermissions;
  }

    async function savePermissions(changes) {
        const gamePermissions = buildChangesToPermissions(changes);
        await updateDoc(userSelected.ref, {
            gamePermissions
        });
    }

  React.useEffect(() => {
    if (!recentResearchers.length){
      getDocumentData();
    }
	}, []);


  return (
    <BeGeneralPage title={t("Manage Game Permissions")}>
        {loading ?
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        :
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "64px",
          }}>
            <Grid container sx={{width: '80%', border: '1px solid rgba(0, 0, 0, 0.6)', borderRadius: '8px', padding: '8px'}}>
              <Grid item xs={3}>
                <Box sx={{maxHeight: '90vh', "overflow-y": 'auto'}}>
                  <Grid container direction={"column"}>
                    {
                      recentResearchers.map((r) => (
                        <Grid item xs={12} onClick={() => { setUserSelected(r) }}>
                          <Paper elevation={0} square={true} sx={{"&:hover": { backgroundColor: r===userSelected ? null : '#D1EAF2' }, backgroundColor: r===userSelected ? '#98D1E3' : null }}>
                          <Tooltip enterNextDelay={500} placement="left" arrow title=
                            {
                              <div>
                                <strong>Email:</strong> {r.email}
                                <br/>
                                <strong>Org:</strong> {r.organization}
                                <br/>
                                <strong>Id:</strong> {r.id}
                              </div>
                            }>
                              <Grid container p={1} sx={{cursor: 'pointer'}}>
                                <Grid item xs={11}>
                                  <Typography>{`${r.name} ${r.surname}`}</Typography>
                                </Grid>
                                  <Grid item xs={1} alignSelf="center">
                                    <ArrowForwardIosIcon sx={{ color: r===userSelected ? '#000' : 'transparent'  }}/>
                                  </Grid>
                              </Grid>
                            </Tooltip>
                          </Paper>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={9}>
                  {
                    userSelected ?
                      <>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Trans>Public Games</Trans>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GamePermissionsTable 
                              headers={headers}
                              gamesInfo={buildGamePermissionsFromReseacher(userSelected)} 
                              onAction={savePermissions}
                              saveIcon={<SaveIcon fontSize="large"/>}
                              deleteIcon={<DeleteIcon fontSize="large"/>}
                              privateList={PRIVATE_GAMES_IDS}
                              showPrivate={false}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Trans>Private Games</Trans>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GamePermissionsTable 
                              headers={headers}
                              gamesInfo={buildGamePermissionsFromReseacher(userSelected)} 
                              onAction={savePermissions}
                              saveIcon={<SaveIcon fontSize="large"/>}
                              deleteIcon={<DeleteIcon fontSize="large"/>}
                              privateList={PRIVATE_GAMES_IDS}
                              showPrivate={true}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </>
                    :
                      null
                  }
              </Grid>
            </Grid>
          </Box>
        }
    </BeGeneralPage>
  )
}

export default ManagePermissionsPage

export const graphQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "administration_validate", "enhanced_table"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;